.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* https://programming.earthonline.us/fantastic-css-border-animation-b02e06828beb */
.radial-linear {
  background: 
      linear-gradient(90deg, #fff 5%, transparent 0) repeat-x,
      linear-gradient(90deg, #fff 5%, transparent 0) repeat-x,
      linear-gradient(0deg, #fff 5%, transparent 0) repeat-y,
      linear-gradient(0deg, #fff 5%, transparent 0) repeat-y;
  background-size: 1300px 10px, 1300px 10px, 10px 1300px, 10px 1300px;
  background-position: 0 0, 0 100%, 0 0, 100% 0;
  animation: linearGradientMove 30s infinite linear;

}

@keyframes linearGradientMove {
  100% {
      background-position: 1300px 0, -1300px 100%, 0 -1300px, 100% 1300px;
  }
}